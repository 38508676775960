.counter-box {
  padding-top: 2px;
  padding-bottom: 2px;
  border: 1px solid #ccc;
  background-color: #ede1d1;
  margin: 2px;
}

.count {
  display: grid;
  justify-content: center;
  text-align: center;
  margin: 10px;
}
