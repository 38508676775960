.main-body {
  height: 95vh;
  width: 100vw;
}

.prescience-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;
  width: 100vw;
  /* max-width: 100%; /* Ensures no overflow */
}

.image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;
  width: 100%;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  height: 10%;
  width: 100%;
}

.text-area {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-size: clamp(1rem, 2.5vw, 1.5rem); /* Responsive font size */
}

.prediction-image {
  height: 50%;
  width: 100%;
  max-width: 100%; /* Prevents overflow */
}

.button {
  font-weight: bold;
  cursor: pointer;
  padding: 0.5em 1em; /* Adjustable based on preference */
}
@media (max-width: 768px) {
  .text-area, .image-container, .button-container {
    width: 90vw; /* Ensure consistency in using viewport widths */
  }

  .text-area {
    min-height: 20vh; /* Use min-height for flexibility */
  }

  .prediction-image {
    height: auto;
    aspect-ratio: 16 / 9; /* Example ratio, adjust as needed */
  }

  .button-container {
    flex-direction: column;
    margin-top: 2rem; /* Use REM for spacing */
  }

  .button {
    margin-bottom: 1rem; /* Consistent REM spacing */
  }
}
