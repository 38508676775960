.button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 15px;
}

.button {
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
}

.button:hover {
  cursor: pointer;
  background: #f0f0f0;
  color: black;
}

.reset {
  background: red;
  color: white;
}

.pass {
  background: blue;
  color: white;
}

.margin-right {
  margin-right: 10px;
}

