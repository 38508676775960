.board {
  text-align: center;
  position: static;
  top: 30%;
  left: 50%;
  height: 60vh;
  width: 100vw;
  overflow-x: auto;
  overflow-y: auto;
}
