.counter {
  display: grid;
  justify-content: center;
  margin-top: 10px;
}

.message-container {
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
