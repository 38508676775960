.image-container {
  display: flex;
  flex-direction: column;
  height: auto;
  width: auto;
  margin-top: 10px;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  height: 10%;
  width: 100%;
}

.text-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10%;
  width: 50%;
  margin-top: 10px;
  font-size: clamp(0.8rem, 2vw, 1.2rem); /* Responsive font size */
  max-width: 90%; /* Adjusts width on smaller screens */
}

.result-image {
  width: 48vw;
  height: 48vh;
  max-width: 100%; /* Ensures the image does not overflow its container */
  max-height: 90vh; /* Limits the height on very tall screens */
}

.notes-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  color: #333;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .image-container, .result-image {
    height: auto; /* Adjusts height for smaller screens */
  }

  .text-area {
    width: 80%; /* Increases width for better readability on small devices */
  }

  .margin-top {
    margin-top: 20px; /* Reduces margin top for space efficiency */
  }

  .result-image {
    width: 90%; /* Increases image width for better fit on small screens */
  }
}

/* Further adjustments for very small screens */
@media (max-width: 480px) {
  .text-area {
    width: 90%; /* Allows the text area to fill more space */
  }

  .button-container, .text-area, .result-image {
    flex-direction: column; /* Stacks elements vertically for better fit */
    align-items: center; /* Centers items to accommodate column layout */
  }
}
