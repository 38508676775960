.image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.margin-top {
  margin-top: 40px;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  height: 10%;
  width: 100%;
}

.text-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10%;
  width: 50%;
  margin-top: 10px;
}

.prediction-image {
  height: 50%;
  width: 100%;
}
